@import '~sweetalert2/dist/sweetalert2.min.css';

body {
  margin: 0;
  font-family: 'Roboto';
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.ml-4 {
  margin-left: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 8px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  color: rgba(0, 0, 0, 0.88);
}

.text-success {
  color: #008f23;
}

.text-error {
  color: #ba2d2c;
}

.text-secondary {
  color: #888888;
}

.bg-success {
  background-color: #c1ffc9 !important;
}

.bg-error {
  background-color: #ffc4c6 !important;
}

.bg-warning {
  background-color: #ffffca;
}

.bg-secondary {
  background-color: #eeeeee;
}

.resource-link {
  display: flex;
  align-items: center;
  color: #1677ff;
  cursor: pointer;
}
.resource-link:hover {
  color: #69b1ff;
}

/* Ant design theme customization */
.ant-card-head,
.ant-collapse-header {
  background-color: #5b5b5b !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.light-card .ant-card-head {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.88) !important;
}

.ant-empty-description {
  color: #ff0000 !important;
}
